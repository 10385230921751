<template>
  <div class="extension-dialog">
    <pageDialog
      title="绑定指挥官门店"
      width="580px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="list-style">
        <el-table :data="shopList" style="width: 100%;" @selection-change="handleSelectionChange" ref="multipleTableRef" @row-click="rowClickHandle" @select="handlerSelect" height="450">
          <el-table-column type="selection" width="55" />
          <el-table-column align="center" prop="shopName" label="指挥官门店" />
        </el-table>
      </div>
      <div class="btn_flex">
        <el-button type="primary" :disabled="selectCheckList&&!selectCheckList.length" @click="bindClick">确认</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,nextTick } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { TUI_GUANG_BAC_IMG, TUI_GUANG_BAC_IMG2 } from '@/common/constant'
import { ElMessage } from 'element-plus'
import { setTuiguangImg } from '@/service/main/base'
import { bindShop } from '@/service/main/shop'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    shopList: {
      type: Array,
      default() {
        return []
      }
    },
    tripartiteShopId: {
      type:String,
      default: null
    }
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible','refresh'],
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }
    const cancelClick = () =>{
      changeDialogVisible(false)
    }
    const multipleTableRef = ref(null)
    const selectCheckList = ref([])
    const rowClickHandle = (row)=>{
      const selectData = selectCheckList.value
      multipleTableRef.value.clearSelection()
      // if( selectData.length == 1 ) {
      //       selectData.forEach(item => {
      //           // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
      //           if (item == row) {
      //             multipleTableRef.value.toggleRowSelection(row, false);
      //           }
      //           // 不然就让当前的一行勾选
      //           else {
      //             multipleTableRef.value.toggleRowSelection(row, true);
      //           }
      //       })
      //   } 
      //   else {
      //     multipleTableRef.value.toggleRowSelection(row, true);
      //   }
      if (selectData.length == 1) {
            const [item] = selectData;
            const shouldSelect = item !== row;
            multipleTableRef.value.toggleRowSelection(row, shouldSelect);
      } else {
          multipleTableRef.value.toggleRowSelection(row, true);
      }
    }
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    const handlerSelect = (selection, row)=> {
      // 清除 所有勾选项
      multipleTableRef.value.clearSelection()
      if(selection.length == 0) return
      multipleTableRef.value.toggleRowSelection(row,true)
    }
    // 当选择项发生变化时会触发该事件
    const handleSelectionChange = (val) =>{
      // console.log('表格的选中 可以获得当前选中的数据',val);
      selectCheckList.value = val
    }
    const bindClick = async() => {
      let params = {
        shopId:selectCheckList.value[0].shopId,
        tripartiteShopId:props.tripartiteShopId,
        type:2
      }
      let data = await bindShop(params)
      if (data.code==0) {
        ElMessage.success({
          message: '绑定成功!',
          type: 'success'
        })
        emit('refresh', false)
      }else{
        ElMessage.error({
          message: res.msg,
          type: 'error'
        })
      }
    }

    return {
      changeDialogVisible,
      TUI_GUANG_BAC_IMG,
      TUI_GUANG_BAC_IMG2,
      multipleTableRef,
      rowClickHandle,
      handlerSelect,
      handleSelectionChange,
      selectCheckList,
      bindClick,
      cancelClick
    }

  }
})
</script>

<style scoped lang="less">
.extension-dialog {
  .btn_flex{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
  // 隐藏全选按钮
  ::v-deep .el-table th.el-table__cell:nth-child(1) .cell {
    visibility: hidden;
  }
}
</style>
